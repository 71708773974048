import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useUserContext } from '@context/UserContext';
import Login from '@containers/Login';
import AccessControl from '@containers/AccessControl';
import Layout from '@components/Layout';

const Routes = () => {
  const { token } = useUserContext();
  return (
    <Switch>
      <Route path="/access-control/:token" component={AccessControl} />
      {!token && <Route path="/" component={Login} />}
      {token && <Route path="/" component={Layout} />}
    </Switch>
  );
};

export default Routes;
